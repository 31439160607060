<!--
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-09-24 09:11:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-11-11 14:20:09
-->
<template>
	<!-- <config-item :label='label'> -->
	<!-- <el-input
      v-model="mValue"
      type="textarea"
      autosize
      v-bind="mOptions"
      :placeholder='mOptions.placeholder'
      size='small'
    ></el-input> -->
	<div class="mt20">
		<MiniMCE id="richText" outputFormat="html" style="width: 100%; height: 650px" :placeholder="mOptions.placeholder" v-model="mValue" />
	</div>
	<!-- </config-item> -->
</template>

<script>
import schemaMixin from '@/mixin/schemaMixin';
export default {
	name: 'SchemaRichtext',
	mixins: [schemaMixin],
	created() {
		this.$nextTick(() => {
			console.log(window.document.getElementById('richText'), '初始化富文本');
			// if(window.tinymce){
			//   window.tinymce.init({
			//     fontsize_formats: "8pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 24pt 36pt"
			// })
			// }
			// 当观察到变动时执行的回调函数
			const callback = function (mutationsList) {
				for (let mutation of mutationsList) {
					if (mutation.type === 'childList') {
						console.log('TODO someting');
					} else if (mutation.type === 'attributes') {
						console.log('TODO someting');
					}
				}
			};

			// 创建一个观察器实例并传入回调函数
			const observer = new MutationObserver(callback);

			// 选择需要观察变动的节点
			const targetNode = window.document.getElementById('richText');

			// 观察器的配置（需要观察什么变动）
			const config = { attributes: true, childList: true, subtree: true, characterData: true };

			// 以上述配置开始观察目标节点
			observer.observe(targetNode, config);

			// 之后，可停止观察
			observer.disconnect();
		});
	},
	beforeDestroy() {},
};
</script>
